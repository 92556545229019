import styled from "@emotion/styled";
import littleBugs from "../../../assets/images/bug-icons/littleBugs.svg";
import windowIcon from "../../../assets/images/landing-window.svg";
import cards from "../../../assets/images/credit-cards.svg";
import { ContainedButton } from "../../shared/components/buttons/ContainedButton";
import { useSelector } from "react-redux";
import { getUser } from "../../utils";
import { AccountType } from "../../utils/accountType";

export const QaSkillsSection = () => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const currentUser = getUser() as string;
  let userDetails = JSON.parse(currentUser);

  return (
    <Container>
      <BugsGroup
        src={littleBugs}
        alt="Bugs icon"
        className="animate__animated animate__fadeIn"
      />
      <ContentContainer className="animate__animated animate__fadeInLeftBig">
        {/* <Title>QASkills</Title> */}
        <Description>
          <h1>
            <b>Looking to start a new career in IT?</b>
          </h1>
          <br />
          <StyledHeading2>
            Become a qualified software tester in 4 months with our 12-step
            software skills roadmap.
          </StyledHeading2>
          <br />
          <h3>Get your dream job. Be happy!</h3>
        </Description>

        {isLoggedIn &&
          userDetails.type === AccountType.free &&
          userDetails.usedFreemim === false && (
            <p
              style={{
                paddingTop: "1rem",
                fontSize: "20px",
              }}
            >
              Get 14 days of Premium access — free. <br />
              No credit card required.
            </p>
          )}

        <ContainedButton
          text="Buy QASkills"
          variant="contained"
          disabled={false}
          icon={cards}
          fromPage="homepage button from the top."
        />
      </ContentContainer>
      <IconWrapper className="animate__animated animate__fadeInRightBig">
        <img src={windowIcon} alt="Window icon" />
      </IconWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 50%;
  @media (max-width: 1200px) {
    width: 85%;
    top: 0;
    transform: none;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
  top: 20%;
  transform: translateY(-20%);
  vertical-align: middle;
  justify-content: center;
`;

const BugsGroup = styled.img`
  position: absolute;
  left: 4rem;
  width: 55%;
  @media (max-width: 1200px) {
    width: 80%;
  }
  @media (max-width: 820px) {
    width: 70%;
  }
`;

const Description = styled.div`
  width: 35rem;
  word-break: break-word;
  padding-top: 2rem;
  padding-bottom: 2rem;

  @media (max-width: 920px) {
    width: auto;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-end; 
  width: 50%;
  margin-right: -5rem;
  align-items: center;
  img {
    height: 95%;
  }
  @media (max-width: 1300px) {
    display: none;
  }

  @media (max-width: 1600px) {
    img {
      height: 80% !important;
  }
`;

const StyledHeading2 = styled.h2`
  padding: 0.5rem;
  background-image: linear-gradient(
    -100deg,
    rgba(211, 255, 255, 0.1),
    rgba(0, 240, 208, 0.5) 60%,
    rgba(108, 227, 255, 0.8)
  );
  text-shadow: 2px 2px 10px rgba(101, 101, 101, 1);
`;
