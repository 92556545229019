import { useRef } from "react";
import styled from "@emotion/styled";
import { Typography, useTheme } from "@mui/material";
import playVideoIcon from "../../../../assets/images/play-video-triangle.svg";
import { OutlinedButton } from "../../../shared/components/buttons/OutlinedButton";
import { AnimationOnScroll } from "react-animation-on-scroll";
import TrustpilotWidget from "../../../shared/components/trustpilot-widget/TruspilotWidget";

export const WhatIsQASkills = () => {
  const theme = useTheme();

  const myRef = useRef();

  return (
    <>
      <TitleContainer>
        <AnimationOnScroll animateOnce animateIn="animate__bounceIn">
          <Title>What is QASkills?</Title>
        </AnimationOnScroll>

        <AnimationOnScroll animateOnce animateIn="animate__tada">
          <StyledHeading4 className="text-center">
            We empower people just like you to land rewarding IT positions as
            Software Quality Assurance Engineers.
          </StyledHeading4>
        </AnimationOnScroll>

        {/* <ButtonWrapper>
          <OutlinedButton
            text="Watch the video"
            variant="outlined"
            disabled={false}
            icon={playVideoIcon}
            color="#EB1F5D"
          />
        </ButtonWrapper> */}

        <AnimationOnScroll animateOnce animateIn="animate__fadeIn">
          <StyledTrustpilotWidget>
            <TrustpilotWidget />
          </StyledTrustpilotWidget>
        </AnimationOnScroll>
      </TitleContainer>

      <StyledVerticalLine />
      <SolutionDescription>
        <AnimationOnScroll animateOnce animateIn="animate__fadeInLeftBig">
          <StyledHeading2>What’s the Problem?</StyledHeading2>
          <StyledHeading4>
            Let’s face it. You’re not feeling challenged or satisfied with your
            current professional career which is probably taking a negative toll
            on your life overall. You don’t just want a job. You want something
            rewarding, challenging, and fulfilling. You love technology, you’re
            not interested in becoming a developer. Good news! You don’t have to
            be a developer to work in IT!
          </StyledHeading4>
        </AnimationOnScroll>

        <AnimationOnScroll animateOnce animateIn="animate__fadeInRightBig">
          <StyledHeading2>
            Why <ColoredTitle>You Should Become a QA Engineer</ColoredTitle>
          </StyledHeading2>
          <StyledHeading4>
            CareerBliss has ranked Quality Assurance position as the second
            happiest job when taking a wide variety of factors into account. If
            you’re wondering what it feels like to wake up every day excited to
            leave for work and end the workday feeling satisfied, this career
            might be calling your name. But we understand how difficult it is to
            start something new and turn a new page in your professional life.
            That’s why we’ve developed QA Skills - a comprehensive interactive
            platform designed to train people to become Quality Assurance
            Engineers.
          </StyledHeading4>
        </AnimationOnScroll>
      </SolutionDescription>
      <StyledVerticalLine />
    </>
  );
};

const TitleContainer = styled.div`
  margin: 0 1rem 4rem 1rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

const Title = styled.h2`
  text-align: center;
  padding-bottom: 2rem;
  font-weight: bold;
  padding-top: 2rem;
`;

const TextTypography = styled(Typography)`
  color: #747474;
`;

const SolutionDescription = styled.div`
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2393999FFF' stroke-width='3' stroke-dasharray='15%2c 20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  padding: 3rem;
`;

const TitleTypography = styled(Typography)`
  text-align: left;
  padding-bottom: 2rem;
  padding-left: 0;
  padding-top: 0;
  font-weight: bold;
`;

const StyledTitle = styled.div`
  padding-top: 3rem;
`;

const ColoredTitle = styled.span`
  color: #00acea;
  display: inline;
  font-weight: bold;
`;

const StyledVerticalLine = styled.div`
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2393999FFF' stroke-width='4' stroke-dasharray='10%2c 23' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  height: 100px;
  width: 1px;
  margin: auto;
`;

const StyledHeading2 = styled.h2`
  font-weight: bold;
`;

const StyledHeading4 = styled.h4`
  color: #747474;
`;

const StyledTrustpilotWidget = styled.div`
  margin: 2rem;
`;
