import { styled, Typography } from "@mui/material";
import { Button } from "react-bootstrap";
import rocketImg from "../../../assets/images/rocket.svg";
import axios from "axios";
import { Mixpanel } from "../../../../Mixpanel";
import { getPlatformEndpoint } from "../../../config";
import { getUser } from "../../utils";
import toast from "react-hot-toast";

export const TrialButton = () => {
  const activateFreemium = () => {
    const currentUser = getUser() as string;
    let userDetails = JSON.parse(currentUser);
    const message =
      "14 days trial perid has been activated, enjoy. BR, QASkills Team";

    const promise = axios
      .get(
        getPlatformEndpoint() + `/user/${userDetails?._id}/activatefreemium`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(() => {
        Mixpanel.track("Freemium has been activated.");
      })
      .catch((error: any) => {
        if (error) {
          throw new Error(error.message);
        }
      });

    toast.promise(promise, {
      loading: "Loading, please wait...",
      success: message,
      error: (error) => error.message,
    });
  };

  return (
    <StyledButton onClick={() => activateFreemium()}>
      <div className="icon-container">
        <img src={rocketImg} alt="trial icon" height={30} className="rocket" />
      </div>

      <Typography variant="caption">
        <b>Start 14-DAY FREE trial</b>
      </Typography>
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  position: relative;
  height: 60px;
  width: 220px;
  background: #6c2dc7;
  border-radius: 50px;
  border: none !important;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  border: 2px solid #6c2dc7 !important;

  .icon-container {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 0.8s ease-in-out;
  }

  .rocket {
    transition: transform 0.2s ease-in-out;
  }

  &:hover {
    background: white;
    color: #6c2dc7;
    border-width: 3px;
    box-shadow: 5px 5px 17px 0px rgba(0, 0, 0, 0.75);

    .icon-container {
      transform: translate(165px, -50%);
    }

    &:focus,
    &:active {
      outline: none !important;
      box-shadow: none !important;
      background: white !important;
      color: #6c2dc7;
    }

    .rocket {
      filter: none;
      transform: rotate(45deg);
    }
  }
`;
