import { Col } from "react-bootstrap";
import styled from "@emotion/styled";
import { add } from "../../services/api/TaskSubmissionApi";
import { OutlinedButton } from "../buttons/OutlinedButton";
import contentLearnedIcon from "../../../../assets/images/content-learned.svg";
import questionIcon from "../../../../assets/images/question.svg";
import { ContentDto } from "../../dtos/content.dto";
import toast from "react-hot-toast";
import { useState } from "react";
import LoadingPage from "../LoadingPage";
import axios from "axios";
import { getBeServerEndpoint } from "../../../../config";
import QuizComponent from "../QuizComponent";
import MarkdownWithHighlight from "./MarkdownWithHighlight";
import { checkIfGuestExists } from "../../../utils/guestCheck";
import { LoginPopup } from "../../../login/LoginPopup";
import MarkdownView from "react-showdown";

interface InnerContentModel {
  staticcontent: ContentDto;
  userId: string;
  setTaskSubmissionResponse: (status: string) => void;
}

export const InnerContent = (props: InnerContentModel) => {
  const { staticcontent, userId, setTaskSubmissionResponse } = props;
  const [disabledState, setDisabledState] = useState<boolean>(
    staticcontent.execution === "STARTED"
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [quizData, setQuizData] = useState(null);
  const [isShowLogin, setIsShowLogin] = useState<boolean>(false);

  const CONTENT_LEARNED_LOGIN_MESSAGE = "Login to mark content as learned.";

  const handleContentRead = (staticContent: ContentDto) => {
    setIsLoading(true);
    let taskId = staticContent._id;
    let submittedCode = staticContent.title;

    const taskSubmissionRequest = {
      taskId,
      userId,
      submittedCode,
      staticContent: true,
    };

    const taskSubmissionRequestPromise = add(taskSubmissionRequest)
      .then(() => {
        setTaskSubmissionResponse("STARTED");
        setDisabledState(true);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error?.message) {
          throw new Error(error);
        }
      });

    toast.promise(taskSubmissionRequestPromise, {
      loading: "Loading, please wait...",
      success: "Content has been learned.",
      error: (error) => error?.message,
    });
  };

  const getQuizQuestions = (staticContent: ContentDto) => {
    let taskId = staticContent._id;
    const url = `/staticcontent/task/${taskId}/quiz`;
    setIsLoading(true);
    const headers = { Authorization: "Basic dGVzdDp0ZXN0" };

    const promise = axios
      .get(getBeServerEndpoint() + url, {
        headers,
      })
      .then((response: any) => {
        setQuizData(response.data?.quizObject);
        setIsLoading(false);
        setOpen(true);
      })
      .catch(() => {
        setIsLoading(false);
        setOpen(false);
      });
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      {open ? (
        <QuizComponent
          setOpenPopup={setOpen}
          isOpen={open}
          quizData={quizData}
        />
      ) : (
        ""
      )}
      <Col xs={12} md={12}>
        <TimeConsuming>
          Estimated reading:&nbsp;
          {Math.round(staticcontent.description?.split(" ").length / 130)} min
        </TimeConsuming>
        <MarkdownView
          markdown={staticcontent.description}
          options={{ tables: true, emoji: true }}
        />

        <hr />
        <LoginPopup
          isShowLogin={isShowLogin}
          setIsShowLogin={setIsShowLogin}
          loginMessage={CONTENT_LEARNED_LOGIN_MESSAGE}
        />
        <ButtonWrapper>
          <OutlinedButton
            text="Content learned"
            variant="outlined"
            icon={contentLearnedIcon}
            type="text"
            redirectLink={undefined}
            disabled={disabledState}
            executionMethod={() =>
              checkIfGuestExists()
                ? setIsShowLogin(true)
                : handleContentRead(staticcontent)
            }
            color="#00ACEA"
          />

          <OutlinedButton
            text="Start Quiz"
            variant="outlined"
            disabled={!disabledState || staticcontent.group !== "logicdocs"}
            icon={questionIcon}
            type="text"
            redirectLink={undefined}
            executionMethod={() => getQuizQuestions(staticcontent)}
            color="#362FD9"
            tooltipText={
              staticcontent.group !== "logicdocs" ? "Coming soon" : ""
            }
          />
        </ButtonWrapper>
      </Col>
    </>
  );
};

const TimeConsuming = styled.p`
  font-size: 18px;
  padding-bottom: 1rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
