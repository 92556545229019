import { Typography } from "@mui/material";
import styled from "@emotion/styled";

import littleBugs from "../../../assets/images/bug-icons/littleBugs.svg";
import automationInfraImage from "../../../assets/images/automation-infra/automation-infra.svg";
import cards from "../../../assets/images/credit-cards.svg";
import { ContainedButton } from "../../shared/components/buttons/ContainedButton";
import { VerticalLine } from "../../shared/components/VerticalLine";
import { TestimonialsCarousel } from "../landing-page/testimonials/TestimonialsCarousel";
import { CompanyCarousel } from "../landing-page/testimonials/CompanyCarousel";
import { EndPageNavigation } from "../../shared/components/end-page-navigation/EndPageNavigation";
import { AutomationInfraSlider } from "./AutomatoionInfraSlider";

import textBug from "../../../assets/images/automation-infra/text-bug.svg";
import blueBug from "../../../assets/images/automation-infra/blue-bug.svg";
import redBug from "../../../assets/images/automation-infra/red-bug.svg";
import arrowRight from "../../../assets/images/automation-infra/arrow-right.svg";

import { AutomationInfraCards } from "./AutomationInfraCards";
import { OutlinedButton } from "../../shared/components/buttons/OutlinedButton";
import { AnimationOnScroll } from "react-animation-on-scroll";

export const AutomationInfra = () => {
  return (
    <AutomationInfraContainer>
      <Container>
        <BugsGroup
          src={littleBugs}
          alt="Bugs icon"
          className="animate__animated animate__fadeIn"
        />
        <ContentContainer className="animate__animated animate__fadeInLeftBig">
          {/* <Title variant="h4">AutomationInfra</Title> */}
          <Description>
            <h1>
              <b>
                Learn the fundamentals of Automation Testing Infrastructure.
              </b>
            </h1>
            <br />
            <StyledHeading2>
              Get the skills and expertise you need to succeed in the field.
            </StyledHeading2>
          </Description>
          <ContainedButton
            text="Buy QASkills"
            variant="contained"
            disabled={false}
            icon={cards}
            fromPage="automationInfra page."
          />
        </ContentContainer>
        <IconWrapper className="animate__animated animate__fadeInRightBig">
          <img src={automationInfraImage} alt="Window icon" />
        </IconWrapper>
      </Container>

      <VerticalLine />

      <AnimationOnScroll animateOnce animateIn="animate__bounceIn">
        <Subtitle>AUTOMATION INFRASTRUCTURE FUNDAMENTALS</Subtitle>
      </AnimationOnScroll>

      <TextBug src={textBug} alt="bug" />

      <AnimationOnScroll animateOnce animateIn="animate__tada">
        <StyledHeading4 className="text-center">
          Learn everything you need to know about infrastructure for Automation
          Testing to qualify for rewarding positions, nail interviews, and
          secure and rewarding career in the field.
        </StyledHeading4>
      </AnimationOnScroll>

      <SliderWrapper>
        <SmallVerticalLine />
        <AutomationInfraSlider />
        <SmallVerticalLine />
      </SliderWrapper>

      <SolutionDescription>
        <AnimationOnScroll animateOnce animateIn="animate__fadeInRight">
          <StyledHeading4 className="text-center">
            Automated application testing is a complex task and, as such,
            requires a specific infrastructure and architecture. Many companies
            use automated testing, which is integrated into the CI/CD process,
            and it is essential to know the tools used by multiple companies.
          </StyledHeading4>
        </AnimationOnScroll>
      </SolutionDescription>

      <BlueBug src={blueBug} alt="bug" />
      <RedBug src={redBug} alt="bug" />

      <VerticalLine />

      <AnimationOnScroll animateOnce animateIn="animate__bounceIn">
        <Subtitle>AUTOMATION INFRASTRUCTURE TASKS</Subtitle>
      </AnimationOnScroll>

      <AnimationOnScroll animateOnce animateIn="animate__tada">
        <StyledHeading4 className="text-center">
          Put your theoretical knowledge to the test through real-world tests so
          you can develop the practical skills required to fulfil the demands of
          Automation Testing roles. The best way to explain something is through
          real examples, and that's why we decided to use real examples to
          explain what the infrastructure for automatic testing of an
          application look like.
        </StyledHeading4>
      </AnimationOnScroll>

      <AutomationInfraCards />

      <AnimationOnScroll animateOnce animateIn="animate__tada">
        <StyledHeading4 className="text-center">
          Check more details about Automation Infrastructure tasks
        </StyledHeading4>
      </AnimationOnScroll>

      <ButtonWrapper>
        <OutlinedButton
          text="See more tasks"
          variant="outlined"
          disabled={false}
          icon={arrowRight}
          color="#00ACEA"
          redirectLink="/automation-infra-tasks"
        />
      </ButtonWrapper>

      <VerticalLine />

      <TestimonialsCarousel />

      <CompanyCarousel />

      <EndPageNavigation btnText="Level Up your QASkills" />
    </AutomationInfraContainer>
  );
};

const AutomationInfraContainer = styled.div`
  background: #f6f9fc;
  position: relative;
  padding-left: 10rem;
  padding-right: 10rem;
  @media (max-width: 1200px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  @media (max-width: 1024px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const Container = styled.div`
  display: flex;
  height: 75vh;
  @media (max-width: 1200px) {
    height: 55vh;
  }
  @media (max-width: 820px) {
    height: 45vh;
    margin-bottom: 10rem;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 50%;
  @media (max-width: 1200px) {
    width: 85%;
    top: 0;
    transform: none;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
  top: 20%;
  transform: translateY(-20%);
  vertical-align: middle;
  justify-content: center;
`;

const BugsGroup = styled.img`
  position: absolute;
  left: 4rem;
  width: 55%;
  @media (max-width: 1200px) {
    width: 80%;
  }
  @media (max-width: 820px) {
    width: 70%;
  }
`;

const Title = styled(Typography)`
  font-weight: bold;

  @media (max-width: 770px) {
    font-size: 2.3rem;
    padding: 2rem 2rem;
  }
`;

const Description = styled.div`
  width: 35rem;
  word-break: break-word;
  padding-top: 2rem;
  padding-bottom: 3rem;

  @media (max-width: 920px) {
    width: auto;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 50%;
  margin-right: -5rem;
  align-items: center;
  img {
    height: 95%;
  }
  @media (max-width: 1300px) {
    display: none;
  }

  @media (max-width: 1600px) {
    img {
      height: 80% !important;
  }
`;

const TextTypography = styled(Typography)`
  color: #747474;
  width: 70%;
  margin: auto;
  text-align: center;

  @media (max-width: 1024px) {
    width: 85%;
  }

  @media (max-width: 600px) {
    width: 95%;
  }
`;

const Subtitle = styled.h2`
  text-align: center;
  padding-bottom: 2rem;
  font-weight: bold;
  padding-top: 2rem;

  @media (max-width: 770px) {
    padding: 2rem 2rem;
  }
`;

const SolutionDescription = styled.div`
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2393999FFF' stroke-width='3' stroke-dasharray='15%2c 20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  padding: 3rem;
  margin: 3rem 0;
  @media (max-width: 1024px) {
    padding: 2rem;
  }
`;

const SmallVerticalLine = styled.div`
  border-right: 1.5px solid #00acea;
  height: 50px;
  position: relative;
  margin: auto !important;
  width: 1.5px;
`;

const SliderWrapper = styled.div`
  margin-top: 4rem;
  margin-bottom: 4rem;
`;

const TextBug = styled.img`
  float: right;
  height: 70px;
  margin-top: -30px;
`;

const BlueBug = styled.img`
  float: left;
  margin-top: -30px;
  margin-left: -90px;
  height: 90px;
  @media (max-width: 1200px) {
    margin-left: 0;
  }
`;

const RedBug = styled.img`
  float: right;
  margin-top: -30px;
  margin-right: -90px;
  @media (max-width: 1200px) {
    margin-right: 0;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 5rem;
`;

const StyledHeading2 = styled.h2`
  padding: 0.5rem;
  background-image: linear-gradient(
    -100deg,
    rgba(211, 255, 255, 0.1),
    rgba(0, 240, 208, 0.5) 60%,
    rgba(108, 227, 255, 0.8)
  );
  text-shadow: 2px 2px 10px rgba(101, 101, 101, 1);
`;

const StyledHeading4 = styled.h4`
  color: #747474;
`;
